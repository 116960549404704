<template #thumbnail>
  <div style="padding-top: min(30px, 5%)" id="result-view">
    <div class="result-view-container">
      <h1>
        {{ title }}
      </h1>
    </div>
    <div v-if="article_ids.length > 0" class="result-view-thumbnail-container">
      <Thumbnail
        v-for="(article, index) in getUObjects({ type: 'articles', ids: article_ids,})"
        :key="`thumbnail${index}`"
        :title="article.title"
        :publish_date="getArticleDate(article)"
        :authors="article.authors"
        :thumbnail="article.featured_image"
        :reference="{ category_slug: article.category_slug, article_id: article.id, }"
        :language="is_search ? article.language : ''"
        :dynamic_width="dynamic_width"
       />
    </div>
    <p v-else-if="is_search">
      {{ (getSelectedLanguage() === 'de') ? 'Ihre Suche ergab keine Treffer.' : 'Your search returned no results.' }}
    </p>
  </div>
</template>

<script>
import Thumbnail from '@/components/Thumbnail.component.vue';
import { ArticleDate } from '@/mixins/ArticleDate.mixin.js';
import { Ultra } from '@/mixins/Ultra.mixin.js';
import { Knowledge } from '@/mixins/Knowledge.mixin.js';

export default {
  components: {
    Thumbnail,
  },

  mixins: [ArticleDate, Ultra, Knowledge, ],

  props: [
    'title',
    'article_ids',
    'is_search',
  ],

  data: () => ({
    dynamic_width: '',
  }),

  methods: {
    calcDynWidth() {
      let freeSpace = document.getElementById('result-view').clientWidth;
      let width = getComputedStyle(document.body).getPropertyValue('--thumbnail-width-static');
      let rowSpace = getComputedStyle(document.body).getPropertyValue('--grid-space');

      if(freeSpace >= width && (freeSpace > 0 && width > 0)) {
        let ipr = parseInt(freeSpace / width); //wie viele elemente wuerden ohne space reinpassen (itemsPerRow)
        if((ipr * width + (ipr - 1) * rowSpace) > freeSpace) //falls es mit space freeSpace sprengt
          ipr--; //nimm ein element weniger

        freeSpace -= (ipr - 1) * rowSpace; //ziehe fuer jeden abstand eine rowSpace ab
        width = parseInt(freeSpace / ipr) - (1 / ipr); //der rest freeSpace wird gleichmaessig verteilt, - (1 / ipr) fixt flickern
      }
      this.dynamic_width = width;
    },
  },

  mounted() {
    if(document.getElementById('result-view')) { //das element muss erst verfügbar sein
      this.calcDynWidth();
    }
    window.addEventListener('resize', this.calcDynWidth);
  },

  beforeRouteLeave(to, from, next) {
    window.removeEventListener('resize', this.calcDynWidth);
    next();
  },
}
</script>

<style lang="css" scoped>
.result-view-container { /* dem button aus Home.view.vue nachempfunden */
  height: 36px;
  display: inline-flex;
  align-items: center;
  text-indent: .0892857143em;
  margin-bottom: min(20px, 3%);
}
.result-view-thumbnail-container {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap:  calc(var(--grid-space) * 1px);;
  grid-column-gap:  calc(var(--grid-space) * 1px);;
}
span {
  line-height: normal;
}
</style>
